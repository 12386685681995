<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Đối soát VneID</h6>
        </template>
        <div class="filter">
          <b-row>
            <b-col>
              <b-form-group label="SĐT">
                <b-form-input v-model="filter.phone"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Mã 9Pay">
                <b-form-input v-model="filter.transaction_id"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Mã đối tác">
                <b-form-input v-model="filter.request_id"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Dịch vụ">
                <b-form-select :options="services" v-model="filter.service"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Trạng thái">
                <b-form-select :options="status" v-model="filter.status"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Mã phản hồi">
                <b-form-input v-model="filter.status_code"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Thời gian bắt đầu(*)">
                <date-picker
                    v-model="filter.date_from"
                    type="datetime"
                    placeholder="Chọn thời gian"
                    :input-attr="{ required: 'true', valid_at: '' }"
                    format="YYYY-MM-DD HH:mm:ss"
                    value-type="format"
                ></date-picker>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Thời gian kết thúc(*)">
                <date-picker
                    v-model="filter.date_to"
                    type="datetime"
                    placeholder="Chọn thời gian"
                    :input-attr="{ required: 'true', valid_at: '' }"
                    format="YYYY-MM-DD HH:mm:ss"
                    value-type="format"
                ></date-picker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="text-right">
                <b-button-group>
                  <b-button type="button" variant="primary" @click="search()"><i class="fa fa-search"></i> Tìm kiếm</b-button>
                  <b-button type="button" variant="success" @click="showModal()"><i class="fa fa-download"></i> Xuất Excel</b-button>
                </b-button-group>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="content">
          <b-summary :total="paginate.total" :current-page="paginate.page" :per-page="paginate.limit" :total-page="paginate.totalPage"></b-summary>
          <b-table :items="items" :fields = "fields" ref="table" striped hover responsive caption-top>
            <template #cell(index)="data">
              {{(currentPage - 1) * 25 + (data.index + 1)}}
            </template>
            <template #cell(status)="data">
              {{ transformStatus(data.item.status) }}
            </template>
            <template #cell(created_at)="data">
              {{ formatDate(data.item.created_at) }}
            </template>
          </b-table>

          <div class="mt-3">
            <b-pagination-nav
                v-model="currentPage"
                :link-gen="createLinkPage"
                :number-of-pages="paginate.totalPage"
                use-router
                first-number
                last-number
                align="right"
            ></b-pagination-nav>
          </div>
        </div>
      </b-card>
    </b-card-group>

    <export-modal :modalId="exportId" @exportData="exportData"></export-modal>
    <alert-dialogue ref="confirmDialogue"></alert-dialogue>


    <b-modal id="xl_modal" size="xl" scrollable  title="Chi tiết phản hồi">
      <br />
      <div class="d-block text-center">
        {{ responseDetail }}
      </div>
      <template #modal-footer="{ ok, cancel }">
      </template>
    </b-modal>
  </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import ExportModal from "@/view/pages/components/ExportModal.vue";
import AlertDialogue from "@/view/pages/components/AlertModal.vue";
import router from "@/router";
import BSummary from "@/view/pages/components/Summary.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

Vue.use(Notifications);

const ReconcileRepository = RepositoryFactory.get("reconcile");
export default {
  components: { BSummary, AlertDialogue, ExportModal, DatePicker },
  mixins: [Common],
  data() {
    return {
      exportId: "export_kyc_reconcile",
      items: null,
      responseDetail: null,
      modal_detail: null,
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      filter: {
        date_from: this.getFirstDayOfMonth(),
        date_to: this.getCurrentDay(),
        service: null,
        request_id: null,
        transaction_id: null,
        phone: null,
        status_code: null,
        status: null
      },
      services: [
        { value: "", text: "Tất cả" },
        { value: "ApproveShareKYC", text: "ApproveShareKYC" },
        { value: "FaceMatchLiveness", text: "FaceMatchLiveness" },
      ],
      status: [
        { value: "", text: "Tất cả" },
        { value: "1", text: "SUCCESS" },
      ],
      fields: [
        {
          key: "index",
          label: "Index"
        },
        {
          key: "provider",
          label: "Nhà cung cấp"
        },
        {
          key: "service",
          label: "Dịch vụ"
        },
        {
          key: "transaction_id",
          label: "Mã 9Pay"
        },
        {
          key: "request_id",
          label: "Mã đối tác"
        },
        {
          key: "phone",
          label: "Số điên thoại"
        },
        {
          key: "status_code",
          label: "Mã phản hồi"
        },
        {
          key: "status",
          label: "Trạng thái"
        },
        {
          key: "created_at",
          label: "Ngày tạo"
        },

      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Đối soát VneID", route: "vneid" },
    ]);
  },
  methods: {
    search() {
      this.filter.page = 1;
      router.push({ path: "/reconcile/vneid", query: this.filter }).catch(() => {});

      if (this.currentPage !== 1) {
        this.currentPage = 1;
        return;
      }

      this.getTransactions();
    },
    async getTransactions()
    {
      this.$bus.$emit("show-loading", true);
      this.items = null;
      let params = this.convert(this.filter);
      params.page = this.currentPage;
      let response = await ReconcileRepository.getListVneid(params);

      this.$bus.$emit("show-loading", false);
      let body = response.data;
      if (body.error_code) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      this.items = body.data.data;
      this.process = body.data.sum;
      this.target = body.data.target;
      this.paginate.totalPage = body.data.last_page;
      this.paginate.total = body.data.total;
      this.paginate.page = body.data.current_page;
      this.paginate.limit = body.data.per_page;
    },
    showModal() {
      this.$bvModal.show(this.exportId);
    },
    async exportData(description = null) {
      this.$bus.$emit("show-loading", true);
      let params = this.convert(this.filter);
      params.export_desc = description
      this.$bvModal.hide(this.exportId);

      let response = await ReconcileRepository.exportListVneid(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;
      if (body.error_code) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      this.$bus.$emit("show-loading", false);
      let link = window.location.origin + "/#/reconcile/export-data";
      let cfm = await this.$refs.confirmDialogue.show({
        title: "Download File",
        message: "File đang được xử lý, bạn có thể tải xuống sau ít phút <a href='" + link + "' target='_blank'>TẠI ĐÂY</a>",
        okButton: "Ok",
      });

      if (!cfm) {
        self.$bus.$emit("show-loading", false);
        return (self.errorMessage = "Có lỗi khi lấy thông tin");
      }
    },
    convert(params) {
      Object.keys(params).forEach(key => {
        if (params[key] === null || params[key] == '') {
          delete params[key];
        }
      });
      return params;
    },
    showMore(id, text) {
      this.responseDetail = text;
      this.$bvModal.show("xl_modal");
    },
    transformStatus(status) {
      if (status === 1) {
        return "SUCCESS";
      }
      return "FAILED"
    }
  },
  created() {
    this.getTransactions();
  },
  watch: {
    currentPage(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getTransactions();
      }
    },
  },
};
</script>

<style>
  .mx-datepicker {
    width: 100%;
  }
</style>
